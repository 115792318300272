import type {ReactElement} from 'react';
import React from 'react';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import {Text, TextSize} from '@Components/text';
import {ClickableDiv} from '@Components/clickable-div';
import styles from './controlled-list-item-type-8.module.scss';

export interface ControlledListItemType8Props extends BaseControlledListItem {
  icon?: string;
  iconClasses?: string;
  textClasses?: string;
  rightIcon?: string;
  rightIconClassName?: string;
  isDisabled?: boolean;
  onRightIconClick?(id?: string): void;
}

export function ControlledListItemType8({id = '', className = '', isSelected = false, onClick = (): void => {}, ...props}: ControlledListItemType8Props): ReactElement {
  return (
    <li className={`spacing-p-0 ${styles.listItemContainer} ${isSelected ? styles.containerSelected : ''}`}>
      <ClickableDiv
        key={id}
        onClick={(event) => {
          onClick(id, event);
        }}
        className={`flex-row-align-center _fit-width ${className} ${styles.clickable}`}
      >
        <Text val={props.text} className={` ${styles.containerText}`} size={TextSize.XSMALL} />
      </ClickableDiv>
    </li>
  );
}
