import type {MouseEvent, ReactElement} from 'react';
import React, {useMemo, useState} from 'react';
import type {ControlledListItemType} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {ControlledListItemType1} from '@Components/controlled-list/components/controlled-list-item-type-1';
import {ControlledListItemType2} from '@Components/controlled-list/components/controlled-list-item-type-2';
import {ControlledListItemType3} from '@Components/controlled-list/components/controlled-list-item-type-3';
import {ControlledListItemType4} from '@Components/controlled-list/components/controlled-list-item-type-4';
import {ControlledListItemType5} from '@Components/controlled-list/components/controlled-list-item-type-5';
import {ControlledListHeadingItem} from '@Components/controlled-list/components/controlled-list-heading-item';
import {ControlledListItemType7} from '@Components/controlled-list/components/controlled-list-item-type-7';
import {ControlledListItemType6} from '@Components/controlled-list/components/controlled-list-item-type-6';
import {ControlledListDividerItem} from '@Components/controlled-list/components/controlled-list-divider-item';
import {ControlledListItemTypeButton} from '@Components/controlled-list/components/controlled-list-item-type-button';
import {ControlledListItemType8} from '@Components/controlled-list/components/controlled-list-item-type-8';
import type {DropAnimation} from '@dnd-kit/core';
import {type Active, defaultDropAnimationSideEffects, DndContext, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';

interface ControlledListProps {
  id?: string;
  items: ControlledListItemType[];
  className?: string;
  /**
   * only supported for type 1 and 6
   */
  isDraggable?: boolean;
  /**
   * only supported for controlled type 3 at the moment
   */
  scrollIntoViewIfSelected?: boolean;

  onClick?(listItemId: string, event?: MouseEvent): void;

  onDragStart?(sourceIndex: number): void;

  onDragEnd?(sourceIndex: number, destinationIndex?: number): void;

  scrollIntoViewBlockProperty?: ScrollLogicalPosition;
}

export function ControlledList({
  id = '',
  className = '',
  isDraggable = false,
  onClick = (): void => {},
  onDragStart = (): void => {},
  onDragEnd = (): void => {},
  scrollIntoViewIfSelected = false,
  scrollIntoViewBlockProperty = 'center',
  ...props
}: ControlledListProps): ReactElement {
  const [active, setActive] = useState<Active | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  const activeItem = useMemo(() => props.items.find((item) => item.id === active?.id), [active, props.items]);
  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0',
        },
      },
    }),
  };

  const prepItems = (): Array<ReactElement> => {
    const itemsToDisplay: Array<ReactElement> = [];
    props.items.map((item, index) => {
      let handlers = {};
      if (item.type !== CONTROLLED_LIST_ITEM_TYPE.HEADING && item.type !== CONTROLLED_LIST_ITEM_TYPE.DIVIDER) {
        handlers = {
          onClick: (itemId: string, e: React.MouseEvent<HTMLElement>): void => {
            if (item.onClick) {
              item.onClick(item.id, e);
            }
            onClick(item.id, e);
          },
        };
      }

      let showSelectionCheckmark = {};
      if ('showSelectionCheckmark' in item) {
        showSelectionCheckmark = {
          showSelectionCheckmark: item.showSelectionCheckmark,
        };
      }

      switch (item.type) {
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_1:
          itemsToDisplay.push(
            <ControlledListItemType1
              id={item.id}
              key={item.id}
              className={item.className}
              text={item.text}
              isDraggable={isDraggable}
              subText={item.subText}
              loading={item.loading}
              showEditIcon={item.showEditIcon}
              isSelected={item.isSelected}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2:
          itemsToDisplay.push(
            <ControlledListItemType2
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              subText={item.subText}
              thumbnail={item.thumbnail}
              isSelected={item.isSelected}
              hasPulsatingDot={item.hasPulsatingDot}
              textClasses={item.textClasses}
              activityIndicatorType={item.activityIndicatorType}
              thumbnailContainerClasses={item.thumbnailContainerClasses}
              pill={item.pill}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_3:
          itemsToDisplay.push(
            <ControlledListItemType3
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              textClass={item.textClass}
              subText={item.subText}
              subtextClass={item.subtextClass}
              subItems={item.subItems}
              icon={item.icon}
              listSize={item.listSize}
              onClickSubItem={(subItemId, state): void => {
                if (item.onClickSubItem) {
                  item.onClickSubItem(subItemId, state);
                }
              }}
              isSelected={item.isSelected}
              actions={item.actions}
              containsDangerouslySetText={item.containsDangerouslySetText}
              {...handlers}
              {...showSelectionCheckmark}
              scrollIntoViewIfSelected={scrollIntoViewIfSelected}
              scrollIntoViewBlockProperty={scrollIntoViewBlockProperty}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_4:
          itemsToDisplay.push(<ControlledListItemType4 id={item.id} className={item.className} key={item.id} text={item.text} {...handlers} isSelected={item.isSelected} />);
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_5:
          itemsToDisplay.push(
            <ControlledListItemType5 id={item.id} className={item.className} key={item.id} text={item.text} pill={item.pill} isSelected={item.isSelected} {...handlers} />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.BUTTON:
          itemsToDisplay.push(
            <ControlledListItemTypeButton
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              isSelected={false}
              onClick={item.onClick}
              buttonProps={item.buttonProps}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_6:
          itemsToDisplay.push(
            <ControlledListItemType6
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              color={item.color}
              size={item.size}
              checkboxType={item.checkboxType}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7:
          itemsToDisplay.push(
            <ControlledListItemType7
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              color={item.color}
              icon={item.icon}
              iconClasses={item.iconClasses}
              shortcut={item.shortcut}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              rightIcon={item.rightIcon}
              rightIconClassName={item.rightIconClassName}
              textClasses={item.textClasses}
              listSize={item.listSize}
              onRightIconClick={(idItem): void => {
                if (item.rightIcon && item.onRightIconClick) {
                  item.onRightIconClick(idItem);
                }
              }}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_8:
          itemsToDisplay.push(
            <ControlledListItemType8
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              icon={item.icon}
              iconClasses={item.iconClasses}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              rightIcon={item.rightIcon}
              rightIconClassName={item.rightIconClassName}
              textClasses={item.textClasses}
              listSize={item.listSize}
              onRightIconClick={(idItem): void => {
                if (item.rightIcon && item.onRightIconClick) {
                  item.onRightIconClick(idItem);
                }
              }}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.HEADING:
          itemsToDisplay.push(<ControlledListHeadingItem id={item.id} className={item.className} key={item.id} text={item.text} />);
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DIVIDER:
          itemsToDisplay.push(<ControlledListDividerItem id={item.id} className={item.className} key={item.id} />);
          break;
        default:
          throw new Error('Invalid type for controlled list');
      }

      return itemsToDisplay;
    });

    return itemsToDisplay;
  };

  const getDraggingItem = (): ReactElement | null => {
    if (!activeItem) {
      return null;
    }

    const item = props.items.find((listItem) => {
      return listItem.id === activeItem.id;
    });

    if (!item) {
      return null;
    }

    if (item.type === CONTROLLED_LIST_ITEM_TYPE.DEFAULT_1) {
      return (
        <ControlledListItemType1
          id={item.id}
          key={item.id}
          className={item.className}
          text={item.text}
          isDraggable={isDraggable}
          subText={item.subText}
          loading={item.loading}
          showEditIcon={item.showEditIcon}
          isSelected={item.isSelected}
          onClick={(itemId: string, e: React.MouseEvent<HTMLElement>): void => {
            if (item.onClick) {
              item.onClick(item.id, e);
            }
            onClick(item.id, e);
          }}
        />
      );
    }

    return null;
  };

  const displayDraggableList = (): ReactElement => {
    return (
      <DndContext
        sensors={sensors}
        onDragStart={({active}): void => {
          setActive(active);
          const sourceId = active.id;
          const sourceIndex = props.items.findIndex((item) => {
            return item.id === sourceId;
          });
          onDragStart(sourceIndex);
        }}
        onDragEnd={({active, over}): void => {
          if (!over) {
            return;
          }

          if (active.id === over.id) {
            return;
          }

          const sourceId = active.id;
          const destinationId = over.id;

          const sourceIndex = props.items.findIndex((item) => {
            return item.id === sourceId;
          });
          const destinationIndex = props.items.findIndex((item) => {
            return item.id === destinationId;
          });
          onDragEnd(sourceIndex, destinationIndex);
        }}
        onDragCancel={(): void => {
          setActive(null);
        }}
      >
        <SortableContext
          items={props.items.map((item) => {
            return item.id;
          })}
        >
          {displayList()}
        </SortableContext>
        <DragOverlay dropAnimation={dropAnimationConfig}>{getDraggingItem()}</DragOverlay>
      </DndContext>
    );
  };

  const displayList = (): ReactElement => {
    return (
      <ul key={id} className={className}>
        {prepItems()}
      </ul>
    );
  };

  return isDraggable ? displayDraggableList() : displayList();
}
